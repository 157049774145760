<template>
    <div>
        <section id="partnerDetails" class="box">
            <PartnerInfos :user="user" :counts="counts" @reloadData="getData" />
        </section>

        <section id="partnerDetailsTabs" class="box">
            <b-tabs v-model="activeTab" @input="getTabData">
                <b-tab-item
                    :label="`Attestations (${
                        certificates[certificates.length - 1] &&
                        certificates[certificates.length - 1].status == 'validate'
                            ? 'Valide'
                            : 'Non valide'
                    })`"
                >
                    <CertificateTable :certificates="certificates" :user="user" @reloadData="reloadData" />
                </b-tab-item>
                <b-tab-item :label="`Sites (${countSites})`">
                    <SiteTable :user="user" :display-user="false" @countSites="updateCount" />
                </b-tab-item>
                <b-tab-item :label="`Gains (${user.gains ? parseInt(user.gains * 100) / 100 : 0} €)`">
                    <PartnerDetailEarningTable :tab-data="earnsData" @reloadData="reloadData" />
                </b-tab-item>
                <b-tab-item :label="`Parrainage (${counts.sponsorship ? counts.sponsorship : 0})`">
                    <PartnerDetailSponsorship :tab-data="sponsorshipsData" @reloadData="reloadData" />
                </b-tab-item>
                <b-tab-item :label="`Commandes (${counts.contents})`">
                    <PartnerDetailsContents :tab-data="contentData" @reloadData="reloadData" />
                </b-tab-item>
                <b-tab-item label="Recherche">
                    <PartnerDetailsSearch :user="user" />
                </b-tab-item>
                <b-tab-item label="Notes">
                    <PartnerDetailsNote :tab-data="noteData" @reloadData="reloadData" />
                </b-tab-item>
            </b-tabs>
        </section>
    </div>
</template>

<script>
    import PartnerInfos from '../../../components/Admin/Partner/PartnerInfos'
    import SiteTable from '../../../components/Admin/Partner/SiteTable'
    import CertificateTable from '../../../components/Admin/Partner/CertificateTable'
    import PartnerDetailEarningTable from '../../../components/Admin/Partner/EarningsTable'
    import PartnerDetailSponsorship from '../../../components/Admin/Partner/Sponsorship'
    import PartnerDetailsContents from '../../../components/Admin/Partner/ContentsTab'
    import PartnerDetailsSearch from '../../../components/Admin/Partner/SearchTab'
    import PartnerDetailsNote from '../../../components/Admin/Partner/NoteTab'

    export default {
        name: 'PartnerDetailsView',
        components: {
            PartnerDetailsNote,
            PartnerDetailsSearch,
            PartnerDetailsContents,
            PartnerDetailSponsorship,
            PartnerDetailEarningTable,
            CertificateTable,
            PartnerInfos,
            SiteTable
        },
        data() {
            return {
                activeTab: 0,
                tabData: {},
                user: {},
                counts: [],
                tabs: [],

                countSites: 0,

                certificates: [],
                certificatesData: {},
                earnsData: {},
                sponsorshipsData: [],
                contentData: [],
                noteData: []
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                this.axios
                    .get('/admin/partner-details/' + this.$route.query.id)
                    .then(response => {
                        this.user = response.data.user
                        this.certificates = response.data.certificates
                        this.counts = response.data.counts

                        this.counts.expiration =
                            this.certificates.length == 0
                                ? null
                                : this.certificates[this.certificates.length - 1].expiration
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            },
            updateCount(data) {
                this.countSites = data
            },
            getTabData(index = this.activeTab) {
                switch (index) {
                    case 1:
                        this.certificatesData = {
                            index: this.activeTab,
                            user: this.user
                        }

                        this.getData()

                        break

                    case 2:
                        this.axios
                            .get(`/admin/users/${this.user.id}/earning`)
                            .then(response => {
                                this.earnsData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data.earnings
                                }
                            })
                            .catch(e => {
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 3:
                        this.axios
                            .get(`/admin/users/${this.user.id}/filleuls`)
                            .then(response => {
                                this.sponsorshipsData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data.filleuls
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 4:
                        this.axios
                            .get(`/admin/partner-details/contents/${this.$route.query.id}`)
                            .then(response => {
                                this.contentData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 6:
                        this.axios
                            .get(`/admin/note/${this.$route.query.id}`)
                            .then(response => {
                                this.noteData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    default:
                        break
                }
            },
            reloadData(tab) {
                tab == null ? this.getData() : this.getTabData(tab)
            }
        }
    }
</script>
